.sidebar_logo {
  padding: 15px 20px;
}
.sidebar_logo img {
  width: 100%;
}
.menu_button {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 0px 25px;
}
:where(.css-dev-only-do-not-override-w8mnev) a {
  color: rgba(255, 255, 255, 0.65);
}
.header h3 {
  color: #444;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0px 20px 0px;
}
.welcome_head {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #444;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #cf9a2c;
}
.ant-menu-item .ant-menu-item-active .ant-menu-item-only-child {
  background-color: #cf9a2c;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #001d3d;
  border-color: #001d3d;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner
  .ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner {
  border-color: #001d3d;
  background-color: #001d3d;
}
a:hover {
  color: #001d3d;
}
.ant-checkbox-wrapper .ant-checkbox-inner:hover {
  border-color: #001d3d;
  background-color: #001d3d;
}
